/* ACTION CREATOR FUNCTIONS

Put here the functions that return an action object that can be dispatched
HINT: Always use functions for consistency, don't export plain objects

*/

import * as types from './types';

const setUserLoginToken = data => ({
    type: types.SET_USER_LOGIN_TOKEN,
    payload: { data },
});

const setMerchantBearerToken = data => ({
    type: types.SET_MERCHANT_BEARER_TOKEN,
    payload: { data },
});

const setMerchantBearerTokenTest = data => ({
    type: types.SET_MERCHANT_BEARER_TOKEN_TEST,
    payload: { data },
});

const setSurchxApiProdToken = data => ({
    type: types.SET_INTERPAYMENTS_API_PROD_TOKEN,
    payload: data,
});

const setSurchxApiTestToken = data => ({
    type: types.SET_INTERPAYMENTS_API_TEST_TOKEN,
    payload: data,
});

const pushSurchxApiProdToken = data => ({
    type: types.PUSH_INTERPAYMENTS_API_PROD_TOKEN,
    payload: data,
});

const pushSurchxApiTestToken = data => ({
    type: types.PUSH_INTERPAYMENTS_API_TEST_TOKEN,
    payload: data,
});

export {
    setUserLoginToken,
    setMerchantBearerToken,
    setMerchantBearerTokenTest,
    setSurchxApiProdToken,
    setSurchxApiTestToken,
    pushSurchxApiProdToken,
    pushSurchxApiTestToken,
};
