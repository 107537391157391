/* REDUCER(S)

It's a good practice to define your state shape first.
Based on the state shape, multiple reducers might be defined in this file, combined and exported into a single reducer function.

*/

import { combineReducers } from "redux";
import * as types from "./types";

/* State Shape
{
    user: {
        first_name: undefined,
        last_name: undefined,
        role: undefined,
        phone: undefined,
        email: undefined,
        status: undefined,
        salesPerson_name: undefined,
        salesPerson_email: undefined,
        siteMode: true,
        merchants_id: undefined
    },
}
*/

const userState = {
    first_name: undefined,
    last_name: undefined,
    role: undefined,
    phone: undefined,
    email: undefined,
    salesPerson_name: undefined,
    salesPerson_email: undefined,
    siteMode: true,
    merchants_id: undefined,
    is_payment_verified: undefined,
};

const userReducer = ( state = userState, action ) => {
    switch( action.type ) {
        case types.SET_USER:
            return {
                ...state,
                ...action.payload,
            };
        case types.SET_USER_SITEMODE:
            return {
                ...state,
                siteMode: action.payload.data,
            };
        case types.SET_USER_MERCHANTSID:
            return {
                ...state,
                merchants_id: action.payload.data,
            };
        case types.USER_LOGIN:
        case types.USER_LOGOUT:
        case types.USER_VERIFY_CONFIRMATION:
        case types.USER_VERIFY_UNLOCK:
        case types.USER_FORGOT_PASSWORD:
        case types.USER_CHANGE_PASSWORD:
        case types.USER_RESET_PASSWORD:
        case types.USER_GET_REFUND:
        case types.USER_GET_TF_TOKEN:
        case types.USER_REGISTER_NEW:
        case types.USER_REGISTER_COMPLETE:
        case types.USER_REGISTER_SALES:
        case types.GET_USER_INFO:
            return action.payload;
        default: return state;
    }
}

const reducer = combineReducers( {
    user: userReducer,
} );

export default reducer;