import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Heading from "../../components/Heading";
import TableData from "../../components/TableData";
import { Button } from "flowbite-react";
import BaseAPI from "../../utils/api/base_api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import GenericModal from "../../components/GenericModal";
import AddForm from "./addForm";
import EditForm from "./editForm";
import DisplayForm from "./displayForm";

function UpdateExamTestQuestions() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tableData, setTableData] = useState(false);
  const [userData, setUserData] = useState(false);
  const [data, setData] = useState(false);
  const [questionsData, setQuestionsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({});

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDeleteId, setIsDeleteId] = useState(null);

  const { loginToken } = useSelector((state) => state.tokenReducer.tokens);

  const navigate = useNavigate();
  let { id } = useParams();

  question_type: [
    { label: "Info", value: "info" },
    { label: "Short Answer", value: "short_answer" },
    { label: "Paragraph", value: "paragraph" },
    { label: "Single Choice", value: "single_choice" },
    { label: "Multiple Choice", value: "multiple_choice" },
    { label: "File Upload", value: "file_upload" },
  ];

  follow_question_type: [{ label: "Single Choice", value: "single_choice" }];

  const getAllQuestions = () => {
    BaseAPI.get(`/questionnaires/${id}/all`, {
      headers: {
        Authorization: "Bearer " + loginToken,
      },
    })
      .then((response) => {
        console.log("response.data.data", response.data.data);
        setQuestionsData(response.data.data);
      })
      .catch((error) => console.log("error: ", error));
  };

  useEffect(() => {
    getAllQuestions();

    BaseAPI.get(`/questionnaires/${id}`, {
      headers: {
        Authorization: "Bearer " + loginToken,
      },
    })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => console.log("error: ", error));
  }, []);

  const handleAddQuestion = async (data, e) => {
    console.log("Data", data);

    BaseAPI.defaults.headers["Authorization"] = "Bearer " + loginToken;
    await BaseAPI.post(`/questionnaires/${id}/create`, data)
      .then((response) => {
        toast.info(response.data.msg, {
          position: toast.POSITION.TOP_CENTER,
          className: "questionnaires-status-msg",
        });

        getAllQuestions();
        togglePopup();
      })
      .catch((error) => {
        toast.error(error.response.data.msg, {
          toastId: "questionnaires-error-msg",
        });
      });
  };

  const handleEditQuestion = async (data) => {
    console.log("Data", data);

    BaseAPI.defaults.headers["Authorization"] = "Bearer " + loginToken;
    await BaseAPI.put(`/questionnaires/${id}/${currentQuestion.QID}`, data)
      .then((response) => {
        toast.info(response.data.msg, {
          position: toast.POSITION.TOP_CENTER,
          className: "questionnaires-status-msg",
        });

        getAllQuestions();
        toggleEditPopup();
      })
      .catch((error) => {
        toast.error(error.response.data.msg, {
          toastId: "questionnaires-error-msg",
        });
      });
  };

  const handleUpdateQuestion = async (question) => {
    console.log("question", question);
    setCurrentQuestion(question);
    toggleEditPopup();
  };

  const handleDelete = async (QID) => {
    setIsDeleteOpen(true);
    setIsDeleteId(QID);
  };

  const handleDeleteQuestion = async () => {
    BaseAPI.defaults.headers["Authorization"] = "Bearer " + loginToken;
    await BaseAPI.delete(`/questionnaires/${id}/${isDeleteId}`)
      .then((response) => {
        toast.info(response.data.msg, {
          position: toast.POSITION.TOP_CENTER,
          className: "questionnaires-status-msg",
        });

        setIsDeleteOpen(!isDeleteOpen);
        getAllQuestions();
      })
      .catch((error) => {
        toast.error(error.response.data.msg, {
          toastId: "questionnaires-error-msg",
        });
      });
  };

  const onProcessFiles = (data) => {
    const { uploaded_file, uploaded_filename, index } = data;
    this.onChangeQuestionsHandler(uploaded_file, "media", index);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const toggleEditPopup = () => {
    setIsEditOpen(!isEditOpen);
  };

  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="flex flex-col md:flex-row justify-between mb-2">
              <Heading heading="Questions" />
              <div className="relative lg:w-72 md:w-50 mb-2">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"></div>
              </div>
            </div>
            <div>
              {questionsData.map((question) => {
                return (
                  <DisplayForm
                    question={question}
                    data={data}
                    onDelete={handleDelete}
                    onUpdate={handleUpdateQuestion}
                  />
                );
              })}

              <Button
                className="bg-blue-600 text-white hover:bg-blue-700 md:mr-3 mb-2 mt-2"
                type="button"
                onClick={togglePopup}
              >
                Add Question
              </Button>
              {isOpen ? (
                <GenericModal
                  heading="Add Question"
                  showModal={isOpen}
                  toggle={togglePopup}
                  size="lg"
                  position="center"
                  body={<AddForm data={data} onSubmit={handleAddQuestion} />}
                />
              ) : null}
              {isEditOpen ? (
                <GenericModal
                  heading="Edit Question"
                  showModal={isEditOpen}
                  toggle={toggleEditPopup}
                  size="lg"
                  position="center"
                  body={
                    <EditForm
                      question={currentQuestion}
                      data={data}
                      onSubmit={handleEditQuestion}
                    />
                  }
                />
              ) : null}

              <GenericModal
                heading="Delete Confirmation"
                showModal={isDeleteOpen}
                toggle={toggleDeletePopup}
                size="lg"
                position="center"
                body=<div className="flex flex-col justify-center">
                  <p className="flex text-base items-start">
                    Do you want to delete this question?
                  </p>
                  <span className="flex flex-col items-center">
                    <Button
                      className="bg-red-500 hover:bg-red-600 w-full lg:w-72 md:w-60 mt-2 mb-2"
                      onClick={toggleDeletePopup}
                    >
                      No
                    </Button>
                    <Button
                      className="bg-green-500 hover:bg-green-600 w-full lg:w-72 md:w-60 mb-2"
                      onClick={handleDeleteQuestion}
                    >
                      Yes
                    </Button>
                  </span>
                </div>
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default UpdateExamTestQuestions;
