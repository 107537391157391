import React, { useState, useEffect } from "react";

// import { Routes, Route, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import {
  HashRouter,
  Route,
  Routes,
  Navigate,
  BrowserRouter,
  Outlet,
} from "react-router-dom";

// Redux imports
import store from "./redux/store";
import { Provider, useSelector } from "react-redux";

// Redux setup
import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from "react-router-redux";
var browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, store);

// Notifications import
import { ToastContainer } from "react-toastify";

import "./index.css";

// Import pages
import Home from "./components/homePage/Home";

import Login from "./scenes/Auth/Login";
import Registration from "./scenes/Auth/Registration";
import SignupConfirmation from "./scenes/Auth/SignupConfirmation";
import ConfirmationPage from "./scenes/Auth/ConfirmationPage";
import ForgotPassword from "./scenes/Auth/ForgotPassword";
import ResetPassword from "./scenes/Auth/ResetPassword";

import Dashboard from "./scenes/Dashboard/Dashboard";
import Category from "./scenes/Category/Category";
import SubCategory from "./scenes/SubCategory/SubCategory";
import Exams from "./scenes/Exams/Exams";
import PublicExams from "./scenes/PublicExams/PublicExams";
import UpdateExamTestQuestions from "./scenes/UpdateExamTestQuestions/UpdateExamTestQuestions";
import GiveExamTest from "./scenes/GiveExamTest/GiveExamTest";
import ExamTestResults from "./scenes/ExamTestResults/ExamTestResults";
import ExamTestResult from "./scenes/ExamTestResult/ExamTestResult";

import Tasks from "./scenes/Tasks/Tasks";
import GenericCreateForm from "./components/GenericCreateForm";
import GenericEditForm from "./components/GenericEditForm";
import { useLocation } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { data } from "autoprefixer";

const ComponentRoutes = () => {
  // const location = useLocation();

  // useEffect(() => {
  //   document.querySelector("html").style.scrollBehavior = "auto";
  //   window.scroll({ top: 0 });
  //   document.querySelector("html").style.scrollBehavior = "";
  // }, [location.pathname]); // triggered on route change

  const loggedIn = () => {
    const { loginToken } = useSelector((state) => state.tokenReducer.tokens);
    const token = loginToken;

    if (token === null || token === undefined) {
      return false;
    } else {
      return true;
    }
  };

  const RequireAuth = ({ children }) => {
    const location = useLocation();

    return loggedIn() === true ? (
      children
    ) : (
      <Navigate to="/" replace state={{ path: location.pathname }} />
    );
  };

  const NoRequireAuth = ({ children }) => {
    const location = useLocation();

    return loggedIn() === false ? (
      children
    ) : (
      <Navigate to="/dashboard" replace state={{ path: location.pathname }} />
    );
  };

  return (
    <React.Fragment>
      <ToastContainer
        closeButton={false}
        newestOnTop={true}
        toastClassName={"portal-toast"}
        bodyClassName={"portal-toast-body"}
      />
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            name="Home"
            element={
              <NoRequireAuth>
                <Home />
              </NoRequireAuth>
            }
          />
          <Route
            exact
            path="/login"
            name="Login"
            element={
              <NoRequireAuth>
                <Login />
              </NoRequireAuth>
            }
          />
          <Route
            exact
            path="/signup"
            name="Registration"
            element={
              <NoRequireAuth>
                <Registration />
              </NoRequireAuth>
            }
          />
          <Route
            exact
            path="/email_confirmation"
            name="Registration Confirmation"
            element={
              <NoRequireAuth>
                <SignupConfirmation />
              </NoRequireAuth>
            }
          />
          <Route
            exact
            path="/confirmation/:token"
            name="Confirmation"
            element={
              <NoRequireAuth>
                <ConfirmationPage />
              </NoRequireAuth>
            }
          />
          <Route
            exact
            path="/forgot-password"
            name="Forgot Password"
            element={
              <NoRequireAuth>
                <ForgotPassword />
              </NoRequireAuth>
            }
          />
          <Route
            exact
            path="/reset-password"
            name="Reset Password"
            element={
              <RequireAuth>
                <ResetPassword />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/questionnaires/categories"
            element={
              <RequireAuth>
                <Category />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/questionnaires/sub-categories"
            element={
              <RequireAuth>
                <SubCategory />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/forms/:name/:id"
            element={
              <RequireAuth>
                <GenericEditForm />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/forms/:name"
            element={
              <RequireAuth>
                <GenericCreateForm />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/exams"
            element={
              <RequireAuth>
                <Exams />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/exams/public"
            element={
              <RequireAuth>
                <PublicExams />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/questionnaires/questions/:id"
            element={
              <RequireAuth>
                <UpdateExamTestQuestions />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/questionnaires/:id"
            element={
              <RequireAuth>
                <GiveExamTest />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/exams/submitted-results/:id"
            element={
              <RequireAuth>
                <ExamTestResults />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/exams/:eid/result-answers/:id"
            element={
              <RequireAuth>
                <ExamTestResult />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/tasks"
            element={
              <RequireAuth>
                <Tasks />
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

function App() {
  return (
    <Provider store={store} history={history}>
      <ComponentRoutes />
    </Provider>
  );
}

export default App;
