/* REDUCER(S)

It's a good practice to define your state shape first.
Based on the state shape, multiple reducers might be defined in this file, combined and exported into a single reducer function.

*/

import { combineReducers } from "redux";
import * as types from "./types";

/* State Shape
{
    admin: {

    },
}
*/

const adminReducer = ( state = false, action ) => {
    switch( action.type ) {
        case types.ADMIN_TRANSITION_MERCHANT:
        case types.ADMIN_SET_USER_ROLE:
        case types.ADMIN_ASSIGN_USER:
        case types.ADMIN_GET_MERCHANT_PORTAL_INDEX:
        case types.ADMIN_GET_MERCHANT_CORE_INDEX:
            return action.payload;
        default: return state;
    }
}

const reducer = combineReducers( {
    admin: adminReducer,
} );

export default reducer;
