import { useFormContext } from "react-hook-form";
export const DynamicControl = ({
  inputType,
  fieldName,
  defaultValue,
  options = [],
  config = {},
}) => {
  const { register } = useFormContext();
  switch (inputType) {
    case "text":
      return (
        <input
          id={fieldName}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full lg:w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          type="text"
          {...register(fieldName, config)}
          defaultValue={defaultValue}
        />
      );
    case "textarea":
      return (
        <textarea
          rows="4"
          id={fieldName}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full lg:w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          {...register(fieldName, config)}
          defaultValue={defaultValue}
        ></textarea>
      );
    case "select": {
      return (
        <select
          {...register(fieldName, config)}
          defaultValue={defaultValue}
          name={fieldName}
          id={fieldName}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full lg:w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">Select</option>
          {options.map((option, index) => (
            <>
              {Array.isArray(option) ? (
                option.map((data, key) => (
                  <option key={key} value={data.value}>
                    {data.label}
                  </option>
                ))
              ) : (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              )}
            </>
          ))}
        </select>
      );
    }
    case "number":
      return (
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full lg:w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          name={fieldName}
          type="number"
          id={fieldName}
          {...register(fieldName, config)}
          defaultValue={defaultValue}
        />
      );
    case "radio":
      return (
        <div className="flex flex-row">
          {options.map((option, index) => (
            <div key={index} className="flex items-center mr-4">
              <input
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                type="radio"
                value={option.value}
                id={fieldName}
                {...register(fieldName, config)}
              />
              <label
                className="ml-2 text-sm font-medium text-gray-900"
                htmlFor={fieldName}
              >
                {option.value}
              </label>
            </div>
          ))}
        </div>
      );
    default:
      return (
        <input
          type="text"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      );
  }
};
