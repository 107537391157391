import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

function CircleChart({ data, width, height, options }) {
  ChartJS.register(ArcElement, Tooltip, Legend);
  return (
    <div>
      <Doughnut data={data} width={width} height={height} options={options} />
    </div>
  );
}

export default CircleChart;
