import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { userActions, userOperations } from "../../redux/user/index";
import { toast } from "react-toastify";
import { tokenActions } from "../../redux/tokens/index";
import { useNavigate } from "react-router-dom";


function ConfirmationPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function onCheck() {
    let currentUrl = window.location.href;
    let token = currentUrl.slice(currentUrl.lastIndexOf('/') + 1);

    let data = {confirmation_token: token};

    dispatch(userActions.userVerifyConfirmation(data))
      .then((response) => {
        toast.success(response.value.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/")
      })
      .catch((error) => {
        toast.error(error.response.data.msg, {
          toastId: "login-error",
        });
        navigate("/")
      });
  }

  useEffect(() => {
    onCheck()
  }, []);

  return (
    <div >Loading...</div>
  );
}

export default ConfirmationPage;
