import React from "react";
import RegistrationForm from "../../components/RegistrationForm";
import SideImage from "../../components/SideImage";

function Registration() {
  return (
    <div className="min-w-screen min-h-screen bg-gray-900 flex items-center justify-center px-5 py-5">
      <div
        className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
        style={{ maxWidth: 1000 }}
      >
        <div className="md:flex w-full">
          <SideImage />
          <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
            <div className="text-center mb-10">
              <h1 className="font-bold text-3xl text-gray-900">LearnKidz</h1>
              <p>Register to create an exam.</p>
            </div>
            <RegistrationForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registration;
