import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Heading from "../../components/Heading";
import TableData from "../../components/TableData";
import { Button } from "flowbite-react";
import BaseAPI from '../../utils/api/base_api';
import { toast } from 'react-toastify';


function Category() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tableData, setTableData] = useState(false);
  const [userData, setUserData] = useState(false);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const sizePerPage = 10

  const { loginToken } = useSelector((state) => state.tokenReducer.tokens);
  BaseAPI.defaults.headers['Authorization'] = 'Bearer ' + loginToken;

  const getData = () => {
      BaseAPI.get('/questionnaires/categories/all', {})
      .then(response => {
          let tableData = response.data.data.slice(
              0,
              sizePerPage
          );

          const fillEmpty = sizePerPage - (tableData.length % sizePerPage);

          if (fillEmpty > 0 && fillEmpty < sizePerPage) {
              for (let i = 0; i < fillEmpty; i++) {
                  tableData.push({
                      CID: `empty_${i}`,
                      test_name: `empty_${i}`,
                  });
              }
          }
          setTableData(tableData)
          setUserData(response.data.data)
          setTotalPages(Math.ceil(response.data.data.length/sizePerPage))
      })
      .catch(error => {
          setTableData([])
          setUserData([])
      });
  }
    
  useEffect(() => {
    getData()
  }, []);

  const handleDelete = async (id) => {
      await BaseAPI.delete(`/questionnaires/categories/${id}`)        
      .then(response => {
          toast.info(response.data.msg, {
              position: toast.POSITION.TOP_CENTER,
              className: 'exams-status-msg',
          })
          getData()
      })
      .catch(error => console.log('error: ', error));
  }

  const column = [
    {key: "title", label: "Title", action: null},
    {key: "CREATED_ON", label: "Created Date", action: null},
    {key: "order", label: "Order", action: null},
    {key: "description", label: "Description", action: null},
    {key: "CID", label: "Edit", action: "/forms/categories/CID", action_type: "url"},
    {key: "CID", label: "Delete", action: handleDelete, action_type: "url-function"},
  ]

  const createCategory = () => {
    navigate("/forms/categories");
  };

  const onTableChange = (currentPage) => {
    console.log("currentPage", currentPage)
    setCurrentPage(currentPage)

    const currentIndex = (currentPage - 1) * sizePerPage;
    let data = userData.slice(
        currentIndex,
        currentIndex + sizePerPage
    );

    const fillEmpty = sizePerPage - (data.length % sizePerPage);

    if (fillEmpty > 0 && fillEmpty < sizePerPage) {
        for (let i = 0; i < fillEmpty; i++) {
            data.push({ EID: `empty_${i}`, test_name: `empty_${i}` });
        }
    }

     setTableData(data)
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="flex flex-col md:flex-row justify-between mb-2">
              <Heading heading="Categories" />
              <div>
                <Button
                  className="bg-green-500 hover:bg-green-600 w-full lg:w-72 md:w-64"
                  onClick={createCategory}
                >
                  Create New Category
                </Button>
              </div>
            </div>
            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              <TableData 
                data={tableData}
                column={column}
                onTableChange={onTableChange}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Category;
