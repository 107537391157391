import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Heading from "../../components/Heading";
import TableData from "../../components/TableData";
import { Button } from "flowbite-react";
import BaseAPI from '../../utils/api/base_api';
import { toast } from 'react-toastify';


function PublicExams() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [userData, setUserData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  
  const navigate = useNavigate();

  const sizePerPage = 10

  const { loginToken } = useSelector((state) => state.tokenReducer.tokens);
  BaseAPI.defaults.headers['Authorization'] = 'Bearer ' + loginToken;
  
  const getData = () => {
      BaseAPI.get('/questionnaires/public', {})
      .then(response => {
          let tableData = response.data.data.slice(
              0,
              sizePerPage
          );

          const fillEmpty = sizePerPage - (tableData.length % sizePerPage);

          if (fillEmpty > 0 && fillEmpty < sizePerPage) {
              for (let i = 0; i < fillEmpty; i++) {
                  tableData.push({
                      CID: `empty_${i}`,
                      test_name: `empty_${i}`,
                  });
              }
          }
          setTableData(tableData)
          setUserData(response.data.data)
          setTotalPages(Math.ceil(response.data.data.length/sizePerPage))
      })
      .catch(error => {
          setTableData([])
          setUserData([])
      });
  }
    
  useEffect(() => {
    getData()
  }, []);

  const handleDelete = async (id) => {
      await BaseAPI.delete(`/questionnaires/${id}`)        
      .then(response => {
          toast.info(response.data.msg, {
              position: toast.POSITION.TOP_CENTER,
              className: 'exams-status-msg',
          })
          getData()
      })
      .catch(error => console.log('error: ', error));
  }

  const getTotalQuestions = (row) => {
      let summary = row.summary;
      if(summary && summary.total_questions){
          return row.summary.total_questions
      }else{
          return 0
      }
  }

  const handleGiveExamTest = (EID) => {
    navigate('/questionnaires/' + EID)
  }

  const handleShowResultExamTest = (EID) => {
    navigate('/exams/submitted-results/' + EID)
  }

  const column = [
    {key: "test_name", label: "Name", action: null},
    {key: "company", label: "Company", action: null},
    {key: "created_by", label: "Created By", action: null},
    {key: "total_questions", label: "Total Questions", action: getTotalQuestions, action_type: "function"},
    {key: "test_repeated", label: "Test Repeated", action: null},
    {key: "description", label: "Description", action: null},
    {key: "EID", label: "Take Test", action: handleGiveExamTest, action_type: "url-function"},
    {key: "EID", label: "Show Result Answers", action: handleShowResultExamTest, action_type: "url-function"},
  ]

  const onTableChange = (currentPage) => {
    console.log("currentPage", currentPage)
    setCurrentPage(currentPage)

    const currentIndex = (currentPage - 1) * sizePerPage;
    let data = userData.slice(
        currentIndex,
        currentIndex + sizePerPage
    );

    const fillEmpty = sizePerPage - (data.length % sizePerPage);

    if (fillEmpty > 0 && fillEmpty < sizePerPage) {
        for (let i = 0; i < fillEmpty; i++) {
            data.push({ EID: `empty_${i}`, test_name: `empty_${i}` });
        }
    }

     setTableData(data)
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="flex flex-col md:flex-row justify-between mb-2">
              <Heading heading="Public Exams" />
              <div className="relative lg:w-72 md:w-50 mb-2">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                </div>
              </div>
            </div>
            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              <TableData 
                data={tableData}
                column={column}
                onTableChange={onTableChange}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default PublicExams;
