import React from "react";
import SideImage from "../../components/SideImage";
import ForgotPasswordForm from "../../components/ForgotPasswordForm";

function ForgotPassword() {
  return (
    <div className="min-w-screen min-h-screen bg-gray-900 flex items-center justify-center px-5 py-5">
      <div
        className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
        style={{ maxWidth: 1000 }}
      >
        <div className="md:flex w-full">
          <SideImage />
          <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
            <div className="text-center mb-10">
              <h1 className="font-bold text-3xl text-gray-900">LearnKidz</h1>
              <p>Forgot Your Password?</p>
              <h4>Please enter your valid email to reset the old password.</h4>
            </div>
            <ForgotPasswordForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
