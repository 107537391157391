import React from "react";

function Content() {
  return (
    <>
      <div className="bg-cream">
        <div className="max-w-screen-xl px-8 mx-auto flex flex-col lg:flex-row items-start">
          <div className="flex flex-col w-full lg:w-6/12 justify-center lg:pt-24 items-start text-center lg:text-left mb-5 md:mb-0">
            <h1
              data-aos="fade-right"
              data-aos-once="true"
              className="my-4 text-5xl font-bold leading-tight text-darken"
            >
              <span className="text-yellow-500">Studying</span> Online is now
              much easier
            </h1>
            <p
              data-aos="fade-down"
              data-aos-once="true"
              data-aos-delay="300"
              className="leading-normal text-2xl mb-8"
            >
              LearnKidz is an interesting platform that will teach you in more
              an interactive way
            </p>
            <div
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="700"
              className="w-full md:flex items-center justify-center lg:justify-start md:space-x-5"
            >
              <button className="lg:mx-0 bg-yellow-500 text-white text-xl font-bold rounded-full py-4 px-9 focus:outline-none transform transition hover:scale-110 duration-300 ease-in-out">
                Join for free
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;
