import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FormProvider, useForm } from "react-hook-form";
import { DynamicControl } from "./DynamicControl";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Heading from "./Heading";
import { Button } from "flowbite-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BaseAPI from "../utils/api/base_api";
import { v4 as uuidv4 } from "uuid";

function GenericCreateForm() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [refresh, setRefreshData] = useState(false);

  let { name, id } = useParams();

  const { loginToken } = useSelector((state) => state.tokenReducer.tokens);
  const navigate = useNavigate();

  const formMethods = useForm();
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = formMethods;

  const [fields, setFields] = useState([]);

  useEffect(() => {
    const { getEditData } = require(`../utils/formData/${name}/onEditSubmit`);
    getEditData({ id: id, loginToken: loginToken }).then((response) => {
      reset(response);
    });
  }, []);

  const onSubmit = (data) => {
    const onEditSubmit =
      require(`../utils/formData/${name}/onEditSubmit`).default;
    onEditSubmit({
      id: id,
      data: data,
      loginToken: loginToken,
      navigate: navigate,
    });
  };

  const getOptionsApiCall = async (field) => {
    let options = [];
    await BaseAPI.get(field.api.url)
      .then((response) => {
        response.data.data.map((option) => {
          options.push({
            label: option[field.api.label],
            value: option[field.api.value],
          });
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    return options;
  };

  useEffect(() => {
    // need to replace this import data with api data in the future
    import(`../utils/formData/${name}/fields.js`).then((fieldsData) => {
      fieldsData.fields.map((field) => {
        if (field.api) {
          getOptionsApiCall(field).then((response) => {
            field.options = response;
            setRefreshData(uuidv4());
          });
        }
      });
      setFields(fieldsData.fields);
    });
  }, [name]);

  let formName = name.replace(/-/g, " ");

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="flex flex-col md:flex-row justify-between mb-2">
              <Heading heading={formName} />
            </div>
            {/* Cards */}
            <div className="">
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormProvider {...formMethods}>
                  {fields &&
                    fields.map((field, i) => (
                      <div key={i} className="mb-2">
                        <label
                          className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          htmlFor={field.fieldName}
                        >
                          {field.label}
                        </label>

                        <DynamicControl {...field} />

                        <ErrorMessage
                          errors={errors}
                          name={field.fieldName}
                          render={({ message }) => (
                            <p className="text-red-600">{message}</p>
                          )}
                        />
                      </div>
                    ))}
                </FormProvider>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="mt-2 mb-2 w-full lg:w-96"
                >
                  {isSubmitting ? "Updating" : "Update"}
                </Button>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default GenericCreateForm;
