import { toast } from 'react-toastify';
import BaseAPI from '../../../utils/api/base_api';


export default function onCreateSubmit({data, loginToken, navigate}){
    BaseAPI.defaults.headers['Authorization'] = 'Bearer ' + loginToken;
    BaseAPI.post('/questionnaires/subcategories/create', data)        
    .then(response => {
    	console.log("response", response)
        toast.info(response.data.msg, {
            position: toast.POSITION.TOP_CENTER,
            className: 'questionnaires-status-msg',
        })
        navigate("/questionnaires/sub-categories");
    })
    .catch(error => {
    	console.log("error", error)
        toast.error(error.response.data.msg, {
            toastId: 'questionnaires-error-msg',
        });
        console.log('error: ', error)
    });
}