import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { userActions, userOperations } from "../redux/user/index";
import { toast } from "react-toastify";
import { tokenActions } from "../redux/tokens/index";
import { useNavigate } from "react-router-dom";

function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function onSubmit(data) {
    dispatch(userOperations.userLoginOp(data))
      .then((response) => {
        dispatch(tokenActions.setUserLoginToken(response.data.data.token));
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/dashboard");
      })
      .catch((error) => {
        toast.error(error.response.data.msg, {
          toastId: "login-error",
        });

        if (error.response.data.data.hasOwnProperty("active")) {
          const active = error.response.data.data.active;

          if (!active) {
            navigate("/email_confirmation");
          }
        }
      });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6">
        <label
          htmlFor="email"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Email address
        </label>
        <input
          type="email"
          id="email"
          name="email"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Email"
          {...register("email", { required: true })}
        />
        {errors.email && <span>This field is required</span>}
      </div>
      <div className="mb-6">
        <label
          htmlFor="password"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Password
        </label>
        <input
          type="password"
          id="password"
          name="password"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Password"
          {...register("password", { required: true })}
        />
        {errors.password && <span>This field is required</span>}
      </div>
      <div className="flex justify-between flex-col md:flex-row">
        <button
          type="submit"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Login
        </button>
        <span>
          <a
            href="/forgot-password"
            className="font-medium text-blue-600 hover:underline dark:text-blue-500 float-right"
          >
            Forgot Password?
          </a>
        </span>
      </div>
    </form>
  );
}

export default LoginForm;
