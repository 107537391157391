import rootReducer from './index';
import thunkMiddleware from 'redux-thunk';
import persistState from 'redux-localstorage';
import { createStore, applyMiddleware, compose } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';

const createStoreWithMiddleware = compose(
  persistState(),
  applyMiddleware(
    thunkMiddleware,
    promiseMiddleware({ promiseTypeSuffixes: ['LOADING', 'SUCCESS', 'ERROR'] })
  ),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
)(createStore);

const store = createStoreWithMiddleware(rootReducer);

export default store;