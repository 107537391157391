import { toast } from 'react-toastify';
import BaseAPI from '../../../utils/api/base_api';


export default function onActionSubmit({id, data, loginToken, navigate}){
    delete data.SCID;
    delete data.CREATED_ON;
    delete data._id;

    BaseAPI.defaults.headers['Authorization'] = 'Bearer ' + loginToken;
    BaseAPI.put(`/questionnaires/subcategories/${id}`, data)        
    .then(response => {
        toast.info(response.data.msg, {
            position: toast.POSITION.TOP_CENTER,
            className: 'questionnaires-status-msg',
        })
        navigate("/questionnaires/sub-categories");
    })
    .catch(error => {
        toast.error(error.response.data.msg, {
            toastId: 'questionnaires-error-msg',
        });
        console.log('error: ', error)
    });
}

export async function getEditData({id, loginToken}){
    let data = {};
    BaseAPI.defaults.headers['Authorization'] = 'Bearer ' + loginToken;
    await BaseAPI.get(`/questionnaires/subcategories/${id}`)     
    .then(response => {data = response.data.data;})
    .catch(error => {console.log("error", error)});
    return data;
}