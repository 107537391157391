import React from "react";

function WelcomeHeading({ heading, userName }) {
  return (
    <div>
      <h1 className="text-xl md:text-2xl text-slate-800 font-bold mb-1 capitalize">
        {heading}, {userName}!
      </h1>
    </div>
  );
}

export default WelcomeHeading;
