import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Heading from "../../components/Heading";
import TableData from "../../components/TableData";
import { Button } from "flowbite-react";
import BaseAPI from "../../utils/api/base_api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { Card } from "flowbite-react";
import { tailwindConfig } from "../../utils/Utils";
import CircleChart from "../../components/Cards/CircleChart";

function ExamTestResults() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [data, setData] = useState([]);
  const [edata, setEData] = useState({});
  const [eudata, setEUData] = useState({});
  const [questionsData, setQuestionsData] = useState([]);

  const question_type = [
    { label: "Short answer", value: "short_answer" },
    { label: "Paragraph", value: "paragraph" },
    { label: "Multiple choice", value: "multiple_choice" },
    { label: "Checkboxes", value: "checkboxes" },
    { label: "File upload", value: "file_upload" },
  ];

  const chartData = {
    labels: ["Total Questions", "Correct Answers"],
    datasets: [
      {
        label: "Correct Answers",
        data: [35, 30],
        backgroundColor: [
          tailwindConfig().theme.colors.indigo[500],
          tailwindConfig().theme.colors.blue[400],
        ],
        hoverBackgroundColor: [
          tailwindConfig().theme.colors.indigo[600],
          tailwindConfig().theme.colors.blue[500],
        ],
        hoverBorderColor: tailwindConfig().theme.colors.white,
      },
    ],
  };

  if (edata.summary && eudata.summary && edata.type == "exam") {
    chartData["datasets"][0]["data"] = [
      edata.summary ? edata.summary.total_questions : 0,
      eudata.summary ? eudata.summary.total_correct_anwers : 0,
    ];
  } else {
    chartData["datasets"][0]["data"] = [
      edata.summary ? edata.summary.total_questions : 0,
      eudata.summary ? eudata.summary.total_anwers : 0,
    ];
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: 80,
  };

  const { loginToken } = useSelector((state) => state.tokenReducer.tokens);

  const navigate = useNavigate();
  let { id, eid } = useParams();

  useEffect(() => {
    BaseAPI.get(`questionnaires/answers/${eid}/submited_results/${id}`, {
      headers: {
        Authorization: "Bearer " + loginToken,
      },
    })
      .then((response) => {
        setEUData(response.data.data);
      })
      .catch((error) => console.log("error: ", error));

    BaseAPI.get(`questionnaires/answers/${id}/submited_results/answers`, {
      headers: {
        Authorization: "Bearer " + loginToken,
      },
    })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => console.log("error: ", error));

    BaseAPI.get(`/questionnaires/${eid}`, {
      headers: {
        Authorization: "Bearer " + loginToken,
      },
    })
      .then((response) => {
        const { data } = { ...response.data };
        setEData(data);
      })
      .catch((error) => console.log("error: ", error));

    BaseAPI.get(`/questionnaires/${eid}/all`, {
      headers: {
        Authorization: "Bearer " + loginToken,
      },
    })
      .then((response) => {
        const { data } = { ...response.data };
        console.log("questionsData", data);
        setQuestionsData(data);
      })
      .catch((error) => console.log("error: ", error));
  }, []);

  const getQuestion = (QID) => {
    const rquestion = questionsData.find((question) => {
      return question.QID == QID;
    });
    return rquestion ? rquestion : {};
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <Heading heading="Show Result" />
            {/* Cards */}
            <div className="grid grid-cols-12 gap-6 mb-2">
              <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200 p-4">
                <div>
                  <div>
                    <h2 className="text-lg">{edata.test_name || ""}</h2>
                    <h6 className="text-lg">{edata.description || ""}</h6>
                  </div>
                </div>

                <header className="px-5 py-4 border-b border-slate-100">
                  <h2 className="font-semibold text-slate-800">
                    Correct Answers
                  </h2>
                </header>
                <CircleChart
                  data={chartData}
                  width={260}
                  height={260}
                  options={chartOptions}
                />
              </div>
            </div>
            <div>
              <div className="mb-2 mt-2">
                <form>
                  <div>
                    <div>
                      {data &&
                        questionsData &&
                        data.map((question, index) => (
                          <Card key={index} className="mb-2 mt-2">
                            <div className="flex flex-col md:flex-row justify-between">
                              <h5 className="text-xl font-bold tracking-tight text-gray-900">
                                {getQuestion(question.QID).question_name}
                              </h5>
                            </div>
                            <div className="flex">
                              {getQuestion(question.QID).question_type ==
                              "info" ? (
                                <div>
                                  <div>
                                    {question.media ? (
                                      <iframe src={question.media}> </iframe>
                                    ) : null}
                                  </div>

                                  <p className="text-base font-normal text-gray-700">
                                    {getQuestion(question.QID).info}
                                  </p>
                                </div>
                              ) : null}

                              {getQuestion(question.QID).question_type ==
                              "short_answer" ? (
                                <div>
                                  <div>
                                    {question.media ? (
                                      <iframe src={question.media}> </iframe>
                                    ) : null}
                                  </div>

                                  <div>
                                    <div>
                                      <input
                                        readOnly={true}
                                        value={question.answer || ""}
                                        type="text"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder="Short Answer Text"
                                        name="short_answer"
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {getQuestion(question.QID).question_type ==
                              "paragraph" ? (
                                <div>
                                  <div>
                                    {question.media ? (
                                      <iframe src={question.media}> </iframe>
                                    ) : null}
                                  </div>

                                  <div>
                                    <div>
                                      <textarea
                                        readOnly={true}
                                        value={question.answer || ""}
                                        type="textarea"
                                        rows="4"
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Long answer text"
                                        name="paragraph"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {getQuestion(question.QID).question_type ==
                              "file_upload" ? (
                                <div>
                                  <div>
                                    {question.media ? (
                                      <iframe src={question.media}> </iframe>
                                    ) : null}
                                  </div>

                                  <div>
                                    {question.file ? (
                                      <div>
                                        <button
                                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2"
                                          target="_blank"
                                          href={question.file}
                                        >
                                          Open/Download
                                        </button>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}

                              {getQuestion(question.QID).question_type ==
                              "single_choice" ? (
                                <div>
                                  {getQuestion(question.QID).options &&
                                    Object.entries(
                                      getQuestion(question.QID).options
                                    ).map(([key, value], option_index) => (
                                      <div key={option_index}>
                                        <div>
                                          {question.media ? (
                                            <iframe src={question.media}>
                                              {" "}
                                            </iframe>
                                          ) : null}
                                        </div>

                                        <div>
                                          <div className="flex flex-row mt-2 mb-2">
                                            <input
                                              disabled={true}
                                              className="mt-[12px] mr-[10px] w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                                              type="radio"
                                              checked={question.answer == key}
                                            />
                                            <input
                                              readOnly={true}
                                              value={value}
                                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              ) : null}

                              {getQuestion(question.QID).question_type ==
                              "multiple_choice" ? (
                                <div>
                                  {getQuestion(question.QID).options &&
                                    Object.entries(
                                      getQuestion(question.QID).options
                                    ).map(([key, value], option_index) => (
                                      <div key={option_index}>
                                        <div>
                                          {question.media ? (
                                            <iframe src={question.media}>
                                              {" "}
                                            </iframe>
                                          ) : null}
                                        </div>

                                        <div>
                                          <div className="flex flex-row mt-2 mb-2">
                                            <input
                                              disabled={true}
                                              type="checkbox"
                                              className="mt-[12px] mr-[10px] w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                              checked={
                                                question.answer &&
                                                question.answer.some(
                                                  (item) => key === item
                                                )
                                              }
                                            />
                                            <input
                                              readOnly={true}
                                              value={value}
                                              type="text"
                                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              ) : null}
                            </div>
                          </Card>
                        ))}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ExamTestResults;
