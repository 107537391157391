import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Card, Button } from "flowbite-react";

function DisplayForm({
  question,
  onSubmit,
  handlePreviousQuestion,
  question_number,
  buttonDisable,
  EID,
  total_questions = 0,
  oldAnswer
}) {
  const { register, watch, reset, getValues, control, handleSubmit } =
    useForm();

  const onProcessFiles = (data) => {
    const { uploaded_file, uploaded_filename } = data;
    reset({ file_path: uploaded_file });
  };

  const onSubmitAction = async (data) => {
    await onSubmit(data);
  };

  const onPreviousQuestionAction = async () => {
    await handlePreviousQuestion();
  };

  useEffect(() => {
    reset({ answer: oldAnswer.answer });
  }, [question]);

  

  return (
    <form onSubmit={handleSubmit(onSubmitAction)} autocomplete="off">
      <Card className="mt-2 mb-2">
        <div className="flex flex-col md:flex-row justify-between">
          <h5 className="text-xl font-bold tracking-tight text-gray-900">
            Question: {question.question_name}
          </h5>
        </div>
        <div className="flex">
          {question.question_type == "info" ? (
            <div>
              <p className="text-base font-normal text-gray-700">
                {question.info}
              </p>
            </div>
          ) : null}

          {question.question_type == "paragraph" ? (
            <div>
              <div>
                <textarea
                  id="answer"
                  name="answer"
                  rows="4"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  type="textarea"
                  {...register("answer", { required: true })}
                  placeholder="Please enter long answer text"
                ></textarea>
              </div>
            </div>
          ) : null}

          {question.question_type == "short_answer" ? (
            <div>
              <div>
                <input
                  id="answer"
                  name="answer"
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  {...register("answer", { required: true })}
                  placeholder="Please enter short answer text"
                ></input>
              </div>
            </div>
          ) : null}

          {question.question_type == "file_upload" ? (
            <div>
              <div>
                <input
                  id="file_path"
                  name="file_path"
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  {...register("file_path", { required: true })}
                ></input>
              </div>
            </div>
          ) : null}

          {question.question_type == "single_choice" ? (
            <div>
              <div>
                {question.options &&
                  Object.entries(question.options).map(
                    ([key, value], index) => (
                      <div key={index}>
                        <div sm={6} md={6}>
                          <div className="mt-2 mb-2">
                            <input
                              name="answer"
                              className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 mt-2 mb-2"
                              type="radio"
                              {...register("answer", { required: true })}
                              value={key}
                            ></input>
                            <label className="ml-2 text-md font-medium text-gray-900">
                              {value}
                            </label>
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          ) : null}

          {question.question_type == "multiple_choice" ? (
            <div>
              <div sm={6} md={6}>
                {question.options &&
                  Object.entries(question.options).map(
                    ([key, value], index) => (
                      <div key={index}>
                        <div sm={6} md={6}>
                          <div className="mt-2 mb-2">
                            <input
                              name="answer"
                              type="checkbox"
                              className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                              {...register("answer", { required: true })}
                              value={key}
                            ></input>
                            <label className="ml-2 text-md font-medium text-gray-900">
                              {value}
                            </label>
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          ) : null}
        </div>
      </Card>

      {total_questions == question_number ? (
        <div>
          <Button
            type="button"
            onClick={buttonDisable ? null : onPreviousQuestionAction}
            disabled={buttonDisable}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2"
          >
            Previous Question
          </Button>
          <Button
            type="submit"
            className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 p-2 rounded mt-2 mb-2 md:w-64 w-full"
            disabled={buttonDisable}
          >
            Submit
          </Button>
        </div>
      ) : total_questions !== 0 &&
        total_questions == question_number ? null : total_questions !== 0 ? (
        <div className="flex flex-row justify-between">
          {question_number !== 1 ? (
            <Button
              type="button"
              onClick={buttonDisable ? null : onPreviousQuestionAction}
              disabled={buttonDisable}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2"
            >
              Previous Question
            </Button>
          ) : null}
          <Button
            type="submit"
            disabled={buttonDisable}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2"
          >
            Next Question
          </Button>
        </div>
      ) : null}
    </form>
  );
}

export default DisplayForm;
