import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Card, Button } from "flowbite-react";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";

function DisplayForm({ question, data, onDelete, onUpdate }) {
  const { register, watch, reset, getValues, control, handleSubmit } =
    useForm();
  const [options, setOptions] = useState([]);
  const [refresh, setRefresh] = useState(1);

  const question_types = [
    { label: "Info", value: "info" },
    { label: "Short Answer", value: "short_answer" },
    { label: "Paragraph", value: "paragraph" },
    { label: "Single Choice", value: "single_choice" },
    { label: "Multiple Choice", value: "multiple_choice" },
    { label: "File Upload", value: "file_upload" },
  ];

  const addOption = () => {
    options.push({
      name: "options." + Math.random().toString(36).substring(2, 15),
    });
    setOptions(options);
    setRefresh(Math.random().toString(36).substring(2, 15));
  };

  const removeOption = (index) => {
    options.splice(index, 1);
    setOptions(options);
    setRefresh(Math.random().toString(36).substring(2, 15));
  };

  const watch_question_type = watch("question_type", false);

  useEffect(() => {
    setOptions([]);
    setRefresh(Math.random().toString(36).substring(2, 15));
  }, [watch_question_type, handleSubmit]);

  return (
    <Card className="mt-2 mb-2">
      <div className="flex flex-col md:flex-row justify-between">
        <h5 className="text-xl font-bold tracking-tight text-gray-900">
          Question: {question.question_name}
        </h5>
        <div className="flex flex-col md:flex-row justify-between mt-2 mb-2">
          <Button
            className="bg-amber-600 text-white hover:bg-amber-700 md:mr-3 mb-2"
            type="button"
            onClick={() => onUpdate(question)}
          >
            <RiEditLine />
          </Button>
          <Button
            className="bg-red-600 text-white hover:bg-red-700"
            type="button"
            onClick={() => onDelete(question.QID)}
          >
            <RiDeleteBinLine />
          </Button>
        </div>
      </div>
      <p className="text-base font-normal text-gray-700">
        <div>
          {question.question_type == "short_answer" && data.type == "exam" ? (
            <div>
              <div>Correct Answer: {question.correct_answer}</div>
            </div>
          ) : null}

          {question.question_type == "paragraph" && data.type == "exam" ? (
            <div>
              <div>Correct Answer: {question.correct_answer}</div>
            </div>
          ) : null}

          {question.question_type == "single_choice" ? (
            <div>
              {Object.entries(question.options).map(([key, value], index) => (
                <div>
                  <div>
                    Option {index + 1}: {value}{" "}
                    {question.correct_answer == key && data.type == "exam"
                      ? " [Correct Answer]"
                      : null}
                  </div>
                </div>
              ))}
            </div>
          ) : null}

          {question.question_type == "multiple_choice" ? (
            <div>
              {Object.entries(question.options).map(([key, value], index) => (
                <div>
                  <div>
                    Option {index + 1}: {value}{" "}
                    {question.correct_answer.indexOf(key) > -1 &&
                    data.type == "exam"
                      ? " [Correct Answer]"
                      : null}
                  </div>
                </div>
              ))}
            </div>
          ) : null}

          {question.question_type == "file_upload" ? (
            <div>
              <div>Help Text: {question.answer}</div>
            </div>
          ) : null}
        </div>
      </p>
    </Card>
  );
}

export default DisplayForm;
