import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Heading from "../../components/Heading";
import TableData from "../../components/TableData";
import { Button } from "flowbite-react";
import BaseAPI from "../../utils/api/base_api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import GenericModal from "../../components/GenericModal";
import DisplayForm from "./displayForm";
import { Card } from "flowbite-react";
import { tailwindConfig } from "../../utils/Utils";
import CircleChart from "../../components/Cards/CircleChart";
import ProgressBar from "@ramonak/react-progress-bar";

function GiveExamTest() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [data, setData] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [questionSummary, setQuestionSummary] = useState({});
  const [answerSummary, setAnswerSummary] = useState({});
  const [answerData, setAnswerData] = useState({});
  const [buttonDisable, setButtonDisable] = useState(false);
  const [question_number, setQuestionNumber] = useState(0);
  const [nextPage, setNextPage] = useState(0);
  const [previousPage, setPreviousPage] = useState(0);
  const [completed, setCompleted] = useState(0);

  const navigate = useNavigate();

  const { loginToken } = useSelector((state) => state.tokenReducer.tokens);

  let { id } = useParams();

  const chartData = {
    labels: ["Total Questions", "Total Anwers"],
    datasets: [
      {
        label: "Total Anwers",
        data: [0, 0],
        backgroundColor: [
          tailwindConfig().theme.colors.indigo[500],
          tailwindConfig().theme.colors.blue[400],
        ],
        hoverBackgroundColor: [
          tailwindConfig().theme.colors.indigo[600],
          tailwindConfig().theme.colors.blue[500],
        ],
        hoverBorderColor: tailwindConfig().theme.colors.white,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: 80,
  };

  chartData["datasets"][0]["data"] = [
    questionSummary ? questionSummary.total_questions : 0,
    answerSummary ? answerSummary.total_anwers : 0,
  ];

  const getCurrentQuestion = (type) => {
    let url = `/questionnaires/${id}/take_question`;
    if (type == "Previous") {
      url = `/questionnaires/${id}/take_question?pageNumber=${previousPage}&pageType=Previous`;
    }
    if (type == "Next") {
      url = `/questionnaires/${id}/take_question?pageNumber=${nextPage}&pageType=Next`;
    }
    BaseAPI.get(url, {
      headers: {
        Authorization: "Bearer " + loginToken,
      },
    })
      .then((response) => {
        const { data } = { ...response.data };
        setCurrentQuestion(data);
        setAnswerSummary(data.summary);
        setNextPage(data.nextPage);
        setPreviousPage(data.previousPage);
        setAnswerData(data.answer);

        if (
          answerSummary &&
          answerSummary.total_anwers >= questionSummary.total_questions
        ) {
          navigate("/exams/public");
        }
      })
      .catch((error) => console.log("error: ", error));
  };

  const getExam = () => {
    BaseAPI.get(`/questionnaires/${id}`, {
      headers: {
        Authorization: "Bearer " + loginToken,
      },
    })
      .then((response) => {
        const { data } = { ...response.data };

        console.log("data", data);

        setData(data);
        setQuestionSummary(data.summary);

        if (
          answerSummary &&
          answerSummary.total_anwers >= questionSummary.total_questions
        ) {
          navigate("/exams/public");
        }
      })
      .catch((error) => console.log("error: ", error));
  };

  useEffect(() => {
    getCurrentQuestion();
    getExam();
  }, []);

  useEffect(() => {
    let total_questions1 = questionSummary ? questionSummary.total_questions : 0
    let total_anwers1 = answerSummary ? answerSummary.total_anwers : 0
    setCompleted((total_anwers1/total_questions1)*100)
  }, [currentQuestion]);

  const handleNextQuestion = async (data) => {
    setButtonDisable(true);

    BaseAPI.defaults.headers["Authorization"] = "Bearer " + loginToken;
    await BaseAPI.post(`/questionnaires/answers/${id}/save`, {
      ...data,
      QID: currentQuestion.QID,
    })
      .then((response) => {
        console.log("response.data", response.data);

        toast.info(response.data.msg, {
          position: toast.POSITION.TOP_CENTER,
          className: "questionnaires-status-msg",
        });

        if (response.data.data.status == "closed") {
          navigate("/exams/public");
        }

        getCurrentQuestion("Next");
        setButtonDisable(false);
      })
      .catch((error) => {
        toast.error(error.response.data.msg, {
          toastId: "questionnaires-error-msg",
        });
        setButtonDisable(false);
      });
  };

  const getCategoryName = (CID) => {
    const title = categoriesList.map((category) => {
      if (category.CID === CID) {
        return category.title;
      }
    });
    return title ? title : "N/A";
  };

  const getSubCategoryName = (SCID) => {
    const title = subCategoriesList.map((subcategory) => {
      if (subcategory.SCID === SCID) {
        return subcategory.title;
      }
    });
    return title ? title : "N/A";
  };

  const handlePreviousQuestion = () => {
    setButtonDisable(true);
    getCurrentQuestion("Previous");
    setButtonDisable(false);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="flex flex-col md:flex-row justify-between mb-2">
              <Heading heading="Take Exam" />
              <div className="relative lg:w-72 md:w-50 mb-2">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"></div>
              </div>
            </div>
            <div>
              <div>
                <div className="bottom-border mb-3">
                  <div>
                    <h2 className="text-lg">Exam Name: {data.test_name}</h2>
                    <h6 className="text-lg">Description: {data.description}</h6>
                  </div>
                </div>
                <div>
                  <div form className="bottom-border mb-3">
                    <div sm={2}>
                      <div className="full-reports__summary__row menu-shadow">
                        <div>
                          <div>
                            <div>
                              <h6>Total Answered:</h6>
                            </div>
                          </div>
                        </div>

                        {/*    <CircleChart
                            data={chartData}
                            width={260}
                            height={260}
                            options={chartOptions}
                          />*/}
                        <div className="w-full lg:w-1/2 mt-2 mb-2">
                          <ProgressBar completed={completed} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div sm={10} md={10}>
                      <div>
                        <DisplayForm
                          handlePreviousQuestion={handlePreviousQuestion}
                          EID={id}
                          question={currentQuestion}
                          onSubmit={handleNextQuestion}
                          total_questions={
                            data.summary && data.summary.total_questions
                          }
                          question_number={nextPage - 1}
                          buttonDisable={buttonDisable}
                          oldAnswer={answerData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default GiveExamTest;
