/* eslint-disable no-useless-catch */
/* OPERATIONS = REDUX THUNKS

This file defines the public interface of the duck -- what can be dispatched from components
Simple operations are just about forwarding an action creator, ex: simpleQuack
Complex operations involve returning a thunk that dispatches multiple actions in a certain order, ex: complexQuack

*/

import * as actions from './actions';
import store from '../store';
import { checkToken } from '../../utils/token-utils';
import api from '../../utils/api/base_api';
import * as types from './types';
import { tokenActions } from '../../redux/tokens/index';


const getUserOp = () => async (dispatch) => {
    try {
        const response = await dispatch(actions.getUserInfo());
        const {
            first_name,
            last_name,
            role,
            email,
            // merchant,
            merchants_id,
            is_payment_verified,
        } = response.value.data;

        dispatch(
            actions.setUser({
                first_name,
                last_name,
                role,
                email,
                merchants_id,
                is_payment_verified,
            })
        );

        localStorage.setItem('surchx_user_name', first_name);

        return response;
    } catch (error) {
        throw error;
    }
};


const userLoginOp = (data) => async (dispatch) => {
  return await api.post('auth/login', data).then(
    (response) => {
      console.log("data1", response)
      dispatch({
        type: types.USER_LOGIN,
        payload: response.data.data,
      });

    const first_name = response.data.data.user.first_name;
    const last_name = response.data.data.user.last_name;
    const role = response.data.data.user.authorisation;
    const email = response.data.data.user.email;
    const is_payment_verified = true;
    const merchant = response.data.data.company;

    dispatch(
        actions.setUser({
            first_name,
            last_name,
            role,
            email,
            merchant,
            is_payment_verified,
        })
    );

      return Promise.resolve(response);
    },
    (error) => {
      console.log("error1", error)
      dispatch({
        type: types.USER_LOGIN,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};

const userLogoutOp = () => async (dispatch) => {
    console.log("userLogoutOp====")
    if (store.getState().tokenReducer.tokens.loginToken !== undefined) {
    console.log("Yessss====")

    api.defaults.headers['Authorization'] =
        'Bearer ' + store.getState().tokenReducer.tokens.loginToken;

      return await api.post('auth/logout').then(
        (response) => {
          console.log("data", response)
          dispatch({
            type: types.USER_LOGOUT,
            payload: response.data,
          });

          dispatch(tokenActions.setUserLoginToken(null))

          return Promise.resolve(response);
        },
        (error) => {
          console.log("error", error)
          dispatch({
            type: types.USER_LOGOUT,
            payload: error,
          });

          return Promise.reject(error);
        }
      );
    }
};

const userVerifyConfirmationOp = (data) => async (dispatch) => {
    try {
        const response = await dispatch(actions.userVerifyConfirmation(data));
        return response;
    } catch (error) {
        throw error;
    }
};

const userGetRefundOp = (data) => async (dispatch) => {
    const { merchantReducer, tokenReducer, userReducer } = store.getState();
    const { merchant_id, merchant_id_test } = merchantReducer.merchant;
    const { bearerToken, bearerTokenTest } = tokenReducer.tokens;
    const { siteMode } = userReducer.user;

    try {
        const newToken = await checkToken(
            siteMode ? bearerToken : bearerTokenTest
        );
        const tfData = {
            config: {
                headers: {
                    Authorization: `Bearer ${newToken}`,
                },
            },
            merchant_id: siteMode ? merchant_id : merchant_id_test,
            siteMode,
        };

        const tfResponse = await dispatch(actions.userGetTfToken(tfData));

        const { token } = tfResponse.value.data;

        const rData = {
            token,
            refundData: data,
            siteMode,
        };
        const refund = await dispatch(actions.userGetRefund(rData));
        return refund;
    } catch (error) {
        throw error;
    }
};

export {
    userLoginOp,
    userLogoutOp,
    userVerifyConfirmationOp,
    userGetRefundOp,
    getUserOp,
};
