import axios from "axios";

import store from "../../redux/store";
import { userOperations } from "../../redux/user";

const config = {
  baseURL: "https://api.learnkidz.com/api/v1",
  headers: {},
};

let axios1 = axios.create(config);

axios1.interceptors.response.use(null, function (error) {
  if (error && (!error.response || error.response.status === 401)) {
    // console.log('error contacting ' + config.baseURL);
    // sessionStorage.clear();
    // localStorage.clear();
    store.dispatch(userOperations.userLogoutOp());
  }
  return Promise.reject(error);
});

/****
 * Used for calling the ans service in conjunction with current answer service
 */
// export const ansService = async (id, currentAnswer) => {
//     const submissionObject = { ...currentAnswer, questionnaire_id: id };
//     console.log('submissionObject: ', submissionObject);

//     axios1.post('/ans/header', submissionObject).then(({ data }) => {
//         console.log('data in header: ', data);
//     });
// };

export default axios1;
