/* ACTION TYPE CONSTANTS

You can use any convention you wish here, but the name should remain UPPER_SNAKE_CASE for consistency.

*/

const ADMIN_TRANSITION_MERCHANT         = 'REDUX/ADMIN/ADMIN_TRANSITION_MERCHANT';
const ADMIN_CHANGE_RISK_STATUS          = 'REDUX/ADMIN/ADMIN_CHANGE_RISK_STATUS';
const ADMIN_ASSIGN_USER                 = 'REDUX/ADMIN/ADMIN_ASSIGN_USER';
const ADMIN_GET_MERCHANT_PORTAL_INDEX   = 'REDUX/ADMIN/ADMIN_GET_MERCHANT_PORTAL_INDEX';
const ADMIN_GET_MERCHANT_CORE_INDEX     = 'REDUX/ADMIN/ADMIN_GET_MERCHANT_CORE_INDEX';
const ADMIN_SET_USER_ROLE               = 'REDUX/ADMIN/ADMIN_SET_USER_ROLE';

export {
    ADMIN_TRANSITION_MERCHANT,
    ADMIN_CHANGE_RISK_STATUS,
    ADMIN_ASSIGN_USER,
    ADMIN_GET_MERCHANT_PORTAL_INDEX,
    ADMIN_GET_MERCHANT_CORE_INDEX,
    ADMIN_SET_USER_ROLE,
};