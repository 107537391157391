import React from "react";
import Header from "./Header";
import Content from "./Content";
import Cloud from "./Cloud";
import InfoCards from "./InfoCards";
import  Footer  from "./Footer";
// import PhysicalClassroom from "./PhysicalClassroom";

function Home() {
  return (
    <div>
      <Header />
      <Content />
      <Cloud />
      <InfoCards />
      <Footer/>
      {/* <PhysicalClassroom /> */}
    </div>
  );
}

export default Home;
