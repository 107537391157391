import {
    CURRENT_USER_PROFILE_LOADED,
    CURRENT_USER_SUMMARIES_LOADED,
    CURRENT_USER_CATEGORY_SUMMARY_LOADED,
} from './types';

const INITIAL_STATE = {
    currentUserProfile: {},
    currentUserSummaries: [{}],
    currentUserCategorySummary: [{}],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CURRENT_USER_PROFILE_LOADED:
            return { ...state, currentUserProfile: action.payload };
        case CURRENT_USER_SUMMARIES_LOADED:
            return { ...state, currentUserSummaries: action.payload };
        case CURRENT_USER_CATEGORY_SUMMARY_LOADED:
            return { ...state, currentUserCategorySummary: action.payload };
        default:
            return state;
    }
};
