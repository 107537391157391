/* REDUCER(S)

It's a good practice to define your state shape first.
Based on the state shape, multiple reducers might be defined in this file, combined and exported into a single reducer function.

*/

import { combineReducers } from 'redux';
import * as types from './types';

/* State Shape
{
    tokens: {
        bearerToken: string,
        bearerTokenTest: string,
        loginToken: string,
		testToken: [strings],
		prodToken: [strings],
    },
}
*/

const initialState = {
    loginToken: undefined,
    testToken: [],
    prodToken: [],
    bearerToken: undefined,
    bearerTokenTest: undefined,
}

const tokenReducer = (state = initialState, action) => {
	switch (action.type) {
        case types.SET_USER_LOGIN_TOKEN:
			return {
                ...state,
                loginToken: action.payload.data,
            };
		case types.SET_MERCHANT_BEARER_TOKEN:
            return {
                ...state,
                bearerToken: action.payload.data,
            };
		case types.SET_MERCHANT_BEARER_TOKEN_TEST:
            return {
                ...state,
                bearerTokenTest: action.payload.data,
            };
        case types.SET_INTERPAYMENTS_API_PROD_TOKEN:
			return {
                ...state,
                prodToken: [...action.payload],
            };
        case types.SET_INTERPAYMENTS_API_TEST_TOKEN:
            return {
                ...state,
                testToken: [...action.payload]
            };
		case types.PUSH_INTERPAYMENTS_API_PROD_TOKEN:
			return {
                ...state,
                prodToken: [...state.prodToken, action.payload]
            };
		case types.PUSH_INTERPAYMENTS_API_TEST_TOKEN:
            return {
                ...state,
                testToken: [...state.testToken, action.payload]
            };
		default:
			return state;
	}
};

const reducer = combineReducers({
	tokens: tokenReducer,
});

export default reducer;