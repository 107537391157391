import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Heading from "../../components/Heading";
import TableData from "../../components/TableData";
import { Button } from "flowbite-react";
import BaseAPI from "../../utils/api/base_api";
import { toast } from "react-toastify";
import GenericModal from "../../components/GenericModal";

function Exams() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tableData, setTableData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [userData, setUserData] = useState(false);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDeleteId, setIsDeleteId] = useState(null);

  const navigate = useNavigate();

  const sizePerPage = 10;

  const { loginToken } = useSelector((state) => state.tokenReducer.tokens);
  BaseAPI.defaults.headers["Authorization"] = "Bearer " + loginToken;

  const getData = () => {
    BaseAPI.get("/questionnaires/all", {})
      .then((response) => {
        let tableData = response.data.data.slice(0, sizePerPage);

        const fillEmpty = sizePerPage - (tableData.length % sizePerPage);

        if (fillEmpty > 0 && fillEmpty < sizePerPage) {
          for (let i = 0; i < fillEmpty; i++) {
            tableData.push({
              CID: `empty_${i}`,
              test_name: `empty_${i}`,
            });
          }
        }
        setTableData(tableData);
        setUserData(response.data.data);
        setTotalPages(Math.ceil(response.data.data.length / sizePerPage));
      })
      .catch((error) => {
        setTableData([]);
        setUserData([]);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = async (id) => {
    setIsDeleteOpen(true);
    setIsDeleteId(id);
  };

  const ActionDelete = async () => {
    await BaseAPI.delete(`/questionnaires/${isDeleteId}`)
      .then((response) => {
        toast.info(response.data.msg, {
          position: toast.POSITION.TOP_CENTER,
          className: "exams-status-msg",
        });
        setIsDeleteOpen(!isDeleteOpen);
        getData();
      })
      .catch((error) => console.log("error: ", error));
  };

  const getTotalQuestions = (row) => {
    let summary = row.summary;
    if (summary && summary.total_questions) {
      return row.summary.total_questions;
    } else {
      return 0;
    }
  };

  const handleAddQuestions = (EID) => {
    navigate("/questionnaires/questions/" + EID);
  };

  const column = [
    { key: "test_name", label: "Name", action: null },
    {
      key: "total_questions",
      label: "Total Questions",
      action: getTotalQuestions,
      action_type: "function",
    },
    { key: "CREATED_ON", label: "Created Date", action: null },
    { key: "type", label: "Type", action: null },
    { key: "visibility", label: "Visibility", action: null },
    { key: "retake", label: "Retake", action: null },
    { key: "description", label: "Description", action: null },
    {
      key: "EID",
      label: "Edit",
      action: "/forms/exams/EID",
      action_type: "url",
    },
    {
      key: "EID",
      label: "Add Questions",
      action: handleAddQuestions,
      action_type: "url-function",
    },
    {
      key: "EID",
      label: "Delete",
      action: handleDelete,
      action_type: "url-function",
    },
  ];

  const createExam = () => {
    navigate("/forms/exams");
  };

  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const onTableChange = (currentPage) => {
    console.log("currentPage", currentPage);
    setCurrentPage(currentPage);

    const currentIndex = (currentPage - 1) * sizePerPage;
    let data = userData.slice(currentIndex, currentIndex + sizePerPage);

    const fillEmpty = sizePerPage - (data.length % sizePerPage);

    if (fillEmpty > 0 && fillEmpty < sizePerPage) {
      for (let i = 0; i < fillEmpty; i++) {
        data.push({ EID: `empty_${i}`, test_name: `empty_${i}` });
      }
    }

    setTableData(data);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="flex flex-col md:flex-row justify-between mb-2">
              <Heading heading="Exams" />
              <div className="relative lg:w-72 md:w-50 mb-2">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"></div>
              </div>
              <div>
                <Button
                  className="bg-green-500 hover:bg-green-600 w-full lg:w-72 md:w-60"
                  onClick={createExam}
                >
                  Create Exam
                </Button>
              </div>
            </div>
            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              <TableData
                data={tableData}
                column={column}
                onTableChange={onTableChange}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </div>
          </div>
        </main>
      </div>

      <GenericModal
        heading="Delete Confirmation"
        showModal={isDeleteOpen}
        toggle={toggleDeletePopup}
        size="lg"
        position="center"
        body=<div className="flex flex-col justify-center">
          <p className=" flex text-base items-start">
            Do you want to delete this exam?
          </p>
          <span className="flex flex-col items-center">
            <Button
              className="bg-red-500 hover:bg-red-600 w-full lg:w-72 md:w-60 mt-2 mb-2"
              onClick={toggleDeletePopup}
            >
              No
            </Button>
            <Button
              className="bg-green-500 hover:bg-green-600 w-full lg:w-72 md:w-60 mb-2"
              onClick={ActionDelete}
            >
              Yes
            </Button>
          </span>
        </div>
      />
    </div>
  );
}

export default Exams;
