import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Heading from "../../components/Heading";
import TableData from "../../components/TableData";
import { Button } from "flowbite-react";
import BaseAPI from "../../utils/api/base_api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

function ExamTestResults() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [data, setData] = useState({});
  const [userData, setUserData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const { loginToken } = useSelector((state) => state.tokenReducer.tokens);

  const navigate = useNavigate();
  let { id } = useParams();

  const sizePerPage = 10;

  useEffect(() => {
    BaseAPI.defaults.headers["Authorization"] = "Bearer " + loginToken;
    BaseAPI.get(`questionnaires/answers/${id}/submited_results`)
      .then((response) => {
        let tableData = response.data.data.slice(0, sizePerPage);

        const fillEmpty = sizePerPage - (tableData.length % sizePerPage);

        if (fillEmpty > 0 && fillEmpty < sizePerPage) {
          for (let i = 0; i < fillEmpty; i++) {
            tableData.push({
              UEID: `empty_${i}`,
              test_name: `empty_${i}`,
            });
          }
        }

        console.log("tableData", tableData);

        setUserData(response.data.data);
        setTableData(tableData);
        setTotalPages(Math.ceil(response.data.data.length / sizePerPage));
      })
      .catch((error) => {
        setUserData([]);
        setTableData([]);
      });

    BaseAPI.get(`/questionnaires/${id}`, {
      headers: {
        Authorization: "Bearer " + loginToken,
      },
    })
      .then((response) => {
        const { data } = { ...response.data };
        setData(data);
      })
      .catch((error) => console.log("error: ", error));
  }, []);

  const onTableChange = (currentPage) => {
    console.log("currentPage", currentPage);
    setCurrentPage(currentPage);

    const currentIndex = (currentPage - 1) * sizePerPage;
    let data = userData.slice(currentIndex, currentIndex + sizePerPage);

    const fillEmpty = sizePerPage - (data.length % sizePerPage);

    if (fillEmpty > 0 && fillEmpty < sizePerPage) {
      for (let i = 0; i < fillEmpty; i++) {
        data.push({ EID: `empty_${i}`, test_name: `empty_${i}` });
      }
    }

    setTableData(data);
  };

  const handleShowResultExamTest = (EID) => {
    navigate(`/exams/${id}/result-answers/${EID}`);
  };

  const total_questions = (row) => {
    if (row.questions) {
      return row.questions.length;
    } else {
      return 0;
    }
  };

  const total_anwers = (row) => {
    return row.total_anwers;
  };

  const getTotalQuestions = (row) => {
    let summary = data.summary;
    if (summary && summary.total_questions) {
      return summary.total_questions;
    } else {
      return 0;
    }
  };

  const getTotalAnswers = (row) => {
    let summary = row.summary;
    if (summary && summary.total_anwers) {
      return row.summary.total_anwers;
    } else {
      return 0;
    }
  };

  const getTotalCorrectAnswers = (row) => {
    let summary = row.summary;
    if (summary && summary.total_correct_anwers) {
      return row.summary.total_correct_anwers;
    } else {
      return 0;
    }
  };

  const column = [
    { key: "email", label: "Submited By", action: null },
    {
      key: "total_questions",
      label: "Total Questions",
      action: getTotalQuestions,
      action_type: "function",
    },
    {
      key: "total_answers",
      label: "Total Anwers",
      action: getTotalAnswers,
      action_type: "function",
    },
    {
      key: "total_correct_answers",
      label: "Total Correct Anwers",
      action: getTotalCorrectAnswers,
      action_type: "function",
    },
    { key: "CREATED_ON", label: "Submited Date", action: null },
    {
      key: "UEID",
      label: "Show Answers",
      action: handleShowResultExamTest,
      action_type: "url-function",
    },
  ];

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="flex flex-col md:flex-row justify-between mb-2">
              <Heading heading="Exam Results" />
              <div className="relative lg:w-72 md:w-50 mb-2">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"></div>
              </div>
            </div>
            <div>
              <TableData
                data={tableData}
                column={column}
                onTableChange={onTableChange}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ExamTestResults;

//     render() {
//         return (
//             <div>
//                 {loading_screen ? <FullScreenLoader /> : null}
//                 <Row className="bottom-border mb-3">
//                     <Col>
//                         <h1>Submitted Results</h1>
//                     </Col>
//                 </Row>
//                 <Container>
//                     <CustomTable
//                         data={tableData}
//                         columns={columns}
//                         totalSize={userData.length}
//                         pages={Math.ceil(
//                             userData.length / sizePerPage
//                         )}
//                         keyField={'id'}
//                         onTableChange={this.onTableChange}
//                         loading={loading}
//                     />
//                 </Container>
//             </div>
//         );
//     }
// }
