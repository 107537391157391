import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import { tailwindConfig } from "../../utils/Utils";
import CircleChart from "../../components/Cards/CircleChart";
import WelcomeHeading from "../../components/WelcomeHeading";
import Calendar from "react-calendar";

function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [value, onChange] = useState(new Date());

  let today = new Date();
  let curHr = today.getHours();
  let greet;
  if (curHr < 12) {
    greet = "Good Morning";
  } else if (curHr >= 12 && curHr <= 17) {
    greet = "Good Afternoon";
  } else if (curHr >= 17 && curHr <= 18) {
    greet = "Good Evening";
  } else if (curHr >= 19 && curHr <= 24) {
    greet = "Good Night";
  }

  const data = {
    labels: ["Correct Answers", "Total Questions"],
    datasets: [
      {
        label: "Correct Answers",
        data: [30, 35],
        backgroundColor: [
          tailwindConfig().theme.colors.indigo[500],
          tailwindConfig().theme.colors.blue[400],
        ],
        hoverBackgroundColor: [
          tailwindConfig().theme.colors.indigo[600],
          tailwindConfig().theme.colors.blue[500],
        ],
        hoverBorderColor: tailwindConfig().theme.colors.white,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: 80,
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <WelcomeHeading heading={greet} userName={"Username"} />
            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200">
                <header className="px-5 py-4 border-b border-slate-100">
                  <h2 className="font-semibold text-slate-800">
                    Correct Answers
                  </h2>
                </header>
                <CircleChart
                  data={data}
                  options={options}
                  width={260}
                  height={260}
                />
              </div>
              <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200">
                <header className="px-5 py-4 border-b border-slate-100">
                  <h2 className="font-semibold text-slate-800">
                    Correct Answers
                  </h2>
                </header>
                <CircleChart
                  data={data}
                  options={options}
                  width={260}
                  height={260}
                />
              </div>
              <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200">
                <header className="px-5 py-4 border-b border-slate-100">
                  <h2 className="font-semibold text-slate-800">
                    Correct Answers
                  </h2>
                </header>
                <CircleChart
                  data={data}
                  options={options}
                  width={260}
                  height={260}
                />
              </div>
              <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200">
                <header className="px-5 py-4 border-b border-slate-100">
                  <h2 className="font-semibold text-slate-800">Calendar</h2>
                </header>
                <Calendar onChange={onChange} value={value} />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
