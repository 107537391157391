import React from "react";
import SideImage from "../../components/SideImage";
import SignupConfirmationForm from "../../components/SignupConfirmationForm";

function SignupConfirmation() {
  return (
    <div className="min-w-screen min-h-screen bg-gray-900 flex items-center justify-center px-5 py-5">
      <div
        className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
        style={{ maxWidth: 1000 }}
      >
        <div className="md:flex w-full">
          <div className="mb-10 p-4">
            <h1 className="font-bold text-3xl text-gray-900 text-center">
              LearnKidz
            </h1>
            <p className="mt-4 text-justify">
              Thanks for signing up! A confirmation link has been sent to your
              email. Please check your inbox for a confirmation email.
            </p>
            <p className="mt-2">
              Re-send your email confirmation if the email is not received.
            </p>
          </div>
          <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
            <SignupConfirmationForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupConfirmation;
