/* ACTION CREATOR FUNCTIONS

Put here the functions that return an action object that can be dispatched
HINT: Always use functions for consistency, don't export plain objects

*/

import * as types from './types';
import store from '../store';
import api from '../../utils/api/base_api';


const getUserInfo = () => ({
    type: types.GET_USER_INFO,
    payload: api.get('user_info'),
});

const userRegisterNew = (data) => ({
    type: types.USER_REGISTER_NEW,
    payload: api.post('user/create', data),
});

const userRegisterComplete = (data) => {
    api.defaults.headers['Authorization'] =
        'Bearer ' + store.getState().tokenReducer.tokens.loginToken;
    return {
        type: types.USER_REGISTER_COMPLETE,
        payload: api.post('complete_registration', data),
    };
};

const userRegisterSales = (data) => {
    api.defaults.headers['Authorization'] =
        'Bearer ' + store.getState().tokenReducer.tokens.loginToken;
    return {
        type: types.USER_REGISTER_SALES,
        payload: api.post('register_sales', data),
    };
};


const userLogin = (data) => ({
    type: types.USER_LOGIN,
    payload: api.post('auth/login', data),
})


const userLogout = () => {
    api.defaults.headers['Authorization'] =
        'Bearer ' + store.getState().tokenReducer.tokens.loginToken;
    return {
        type: types.USER_LOGOUT,
        payload: api.post('auth/logout'),
    };
};

const setUser = (data) => ({
    type: types.SET_USER,
    payload: { ...data },
});

const setUserSitemode = (data) => ({
    type: types.SET_USER_SITEMODE,
    payload: { data },
});

const setUserMerchantsid = (data) => ({
    type: types.SET_USER_MERCHANTSID,
    payload: { data },
});

const userVerifyConfirmation = (data) => {
    return {
        type: types.USER_VERIFY_CONFIRMATION,
        payload: api.post('auth/verify-confirmation', data),
    };
};

const userVerifyUnlock = (data) => {
    return {
        type: types.USER_VERIFY_UNLOCK,
        payload: api.post('unlock_user', data),
    };
};

const userForgotPassword = (data) => {
    return {
        type: types.USER_FORGOT_PASSWORD,
        payload: api.post('forgot_password', data),
    };
};

const userResetPassword = (data) => {
    return {
        type: types.USER_RESET_PASSWORD,
        payload: api.post('reset_password', data),
    };
};

const userChangePassword = (data) => {
    api.defaults.headers['Authorization'] =
        'Bearer ' + store.getState().tokenReducer.tokens.loginToken;

    return {
        type: types.USER_CHANGE_PASSWORD,
        payload: api.post('change_password', data),
    };
};

const userGetRefund = (data) => {
    const { refundData, token, siteMode } = data;
    const { sTxId, amount } = refundData;
    const apiToUse = siteMode ? surchxApi : surchxApiTest;
    apiToUse.defaults.headers['Authorization'] = 'Bearer ' + token;
    return {
        type: types.USER_GET_REFUND,
        payload: apiToUse.post('ch/refund/calc', {
            sTxId,
            amount,
        }),
    };
};

const userGetTfToken = (data) => {
    const { config, merchant_id, siteMode } = data;

    const apiToUse = siteMode ? surchxApi : surchxApiTest;

    return {
        type: types.USER_GET_TF_TOKEN,
        payload: apiToUse.post(`ch/token/${merchant_id}`, config),
    };
};

export {
    userRegisterNew,
    userRegisterComplete,
    userRegisterSales,
    userLogin,
    userLogout,
    setUser,
    setUserSitemode,
    setUserMerchantsid,
    userVerifyConfirmation,
    userVerifyUnlock,
    userForgotPassword,
    userResetPassword,
    userChangePassword,
    userGetRefund,
    userGetTfToken,
    getUserInfo,
};
