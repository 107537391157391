/* ACTION TYPE CONSTANTS

You can use any convention you wish here, but the name should remain UPPER_SNAKE_CASE for consistency.

*/

const USER_LOGIN                = 'REDUX/USER/USER_LOGIN';
const USER_LOGOUT               = 'REDUX/USER/USER_LOGOUT';
const SET_USER                  = 'REDUX/USER/SET_USER';
const SET_USER_SITEMODE         = 'REDUX/USER/SET_USER_SITEMODE';
const SET_USER_MERCHANTSID        = 'REDUX/USER/SET_USER_MERCHANTSID';
const USER_VERIFY_UNLOCK        = 'REDUX/USER/USER_VERIFY_UNLOCK';
const USER_VERIFY_CONFIRMATION  = 'REDUX/USER/USER_VERIFY_CONFIRMATION';
const USER_FORGOT_PASSWORD      = 'REDUX/USER/USER_FORGOT_PASSWORD';
const USER_CHANGE_PASSWORD      = 'REDUX/USER/USER_CHANGE_PASSWORD';
const USER_RESET_PASSWORD       = 'REDUX/USER/USER_RESET_PASSWORD';
const USER_GET_REFUND           = 'REDUX/USER/USER_GET_REFUND';
const USER_GET_TF_TOKEN         = 'REDUX/USER/USER_GET_TF_TOKEN';
const USER_REGISTER_NEW         = 'REDUX/USER/USER_REGISTER_NEW';
const USER_REGISTER_COMPLETE    = 'REDUX/USER/USER_REGISTER_COMPLETE';
const USER_REGISTER_SALES       = 'REDUX/USER/USER_REGISTER_SALES';
const GET_USER_INFO             = 'REDUX/USER/GET_USER_INFO';

export {
    USER_LOGIN,
    USER_LOGOUT,
    SET_USER,
    SET_USER_SITEMODE,
    SET_USER_MERCHANTSID,
    USER_VERIFY_UNLOCK,
    USER_VERIFY_CONFIRMATION,
    USER_FORGOT_PASSWORD,
    USER_CHANGE_PASSWORD,
    USER_RESET_PASSWORD,
    USER_GET_REFUND,
    USER_GET_TF_TOKEN,
    USER_REGISTER_NEW,
    USER_REGISTER_COMPLETE,
    USER_REGISTER_SALES,
    GET_USER_INFO,
};