import {
    CATEGORY_SUMMARY_QUESTIONS_REQUESTED,
    CATEGORY_SUMMARY_QUESTIONS_SUCCESS,
    CATEGORY_SUMMARY_QUESTIONS_FAILURE,
} from './types';

const INITIAL_STATE = {
    categorySummaryIsLoading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CATEGORY_SUMMARY_QUESTIONS_REQUESTED:
            return { ...state, categorySummaryIsLoading: true };
        case CATEGORY_SUMMARY_QUESTIONS_SUCCESS:
            return { ...state, categorySummaryIsLoading: false };
        case CATEGORY_SUMMARY_QUESTIONS_FAILURE:
            return { ...state, categorySummaryIsLoading: false };
        default:
            return state;
    }
};
