import React from "react";
import { Table } from "flowbite-react";
import { Pagination } from "flowbite-react";

function TableData({ data, column, onTableChange, currentPage, totalPages }) {
  return (
    <>
      <div className="col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-slate-200">
        <div className="p-3">
          {/* Table */}
          <div className="overflow-auto">
            <Table>
              <Table.Head>
                {column.map(function (cell) {
                  return (
                    <>
                      <Table.HeadCell>{cell.label}</Table.HeadCell>
                    </>
                  );
                })}
              </Table.Head>
              <Table.Body className="divide-y">
                {data.length > 0 &&
                  data.map((row) => (
                    <Table.Row
                      key={row._id}
                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                    >
                      {column.map(function (cell) {
                        return (
                          <>
                            <Table.Cell
                              className="whitespace-nowrap font-medium text-gray-900 dark:text-white"
                              key={cell.key}
                            >
                              {row._id ? (
                                cell.action ? (
                                  cell.action_type === "url" ? (
                                    <a
                                      href={cell.action
                                        .toString()
                                        .replace(
                                          new RegExp(cell.key, "g"),
                                          row[cell.key]
                                        )}
                                      className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                                    >
                                      {cell.label}
                                    </a>
                                  ) : cell.action_type === "function" ? (
                                    cell.action(row)
                                  ) : cell.action_type === "url-function" ? (
                                    <a
                                      onClick={() => cell.action(row[cell.key])}
                                      href="javascript:void(0)"
                                      className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                                    >
                                      {cell.label}
                                    </a>
                                  ) : null
                                ) : (
                                  row[cell.key]
                                )
                              ) : null}
                            </Table.Cell>
                          </>
                        );
                      })}
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
      {data.length > 0 ? (
        <div className="flex col-span-full xl:col-span-12 items-center justify-center text-center">
          <Pagination
            currentPage={currentPage}
            layout="pagination"
            showIcons={true}
            totalPages={totalPages}
            onPageChange={onTableChange}
          />
        </div>
      ) : null}
    </>
  );
}

export default TableData;
