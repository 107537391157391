import React from "react";

function Heading({ heading }) {
  return (
    <div>
      {/* Content */}
      <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-1 capitalize">
        {heading}
      </h1>
    </div>
  );
}

export default Heading;
