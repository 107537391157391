import api from './api/base_api.js';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import store from '../redux/store';

// const checkToken = async () => {
export const checkToken = async (bearerToken, siteMode) => {
    if (isExpired(bearerToken)) {
        let returnToken = '';
        await api
            .post(
                'token',
                { siteMode },
                {
                    headers: {
                        Authorization:
                            'Bearer ' +
                            store.getState().tokenReducer.tokens.loginToken,
                    },
                }
            )
            .then(response => {
                returnToken = response.data.bearer_token;
            })
            .catch(error => {
                // console.log('ERROR ERROR: ', error);
            });
        return returnToken;
    } else {
        return bearerToken;
    }
};

export const getTemporaryToken = async (siteMode, merchant_id) => {
    api.defaults.headers['Authorization'] =
        'Bearer ' + store.getState().tokenReducer.tokens.loginToken;

    try {
        const response = await api.post('token', { siteMode, merchant_id });
        return response.data.bearerToken;
    } catch(error) {
        throw error;
    }
};

export const isExpired = token => {
    const dateNow = moment().valueOf() / 1000;
    if (jwtDecode(token).exp < dateNow) {
        return true;
    } else {
        return false;
    }
};

export const getExpiration = token => {
    return moment.unix(jwtDecode(token).exp).format('MMMM Do YYYY, h:mm:ss a');
};

export const goLiveDate = token => {
    return moment
        .unix(jwtDecode(token).iat)
        .add(31, 'days')
        .format('MM/DD/YY');
};

export const getData = token => {
    const theToken = jwtDecode(token);
    if (theToken.data !== undefined) {
        return theToken.data;
    } else {
        return ['Unnamed Token'];
    }
};

export const arrayContainsAnotherArray = (needle, haystack) => {
    for (var i = 0; i < needle.length; i++) {
        if (haystack.indexOf(needle[i]) === -1) return false;
    }
    return true;
}
