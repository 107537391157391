import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { RiDeleteBinLine } from "react-icons/ri";

function EditForm({ question, data, onSubmit }) {
  const { register, watch, reset, getValues, control, handleSubmit } =
    useForm();

  const tempOptions = [];
  question.options &&
    Object.entries(question.options).map(([key, value], index) => {
      tempOptions.push({
        name: "options." + key,
      });
    });
  const [options, setOptions] = useState(tempOptions);
  const [refresh, setRefresh] = useState(1);

  const question_types = [
    { label: "Info", value: "info" },
    { label: "Short Answer", value: "short_answer" },
    { label: "Paragraph", value: "paragraph" },
    { label: "Single Choice", value: "single_choice" },
    { label: "Multiple Choice", value: "multiple_choice" },
    { label: "File Upload", value: "file_upload" },
  ];

  useEffect(() => {
    reset(question);
  }, [question]);

  const addOption = () => {
    options.push({
      name: "options." + Math.random().toString(36).substring(2, 15),
    });
    setOptions(options);
    setRefresh(Math.random().toString(36).substring(2, 15));
  };

  const removeOption = (index) => {
    options.splice(index, 1);
    setOptions(options);
    setRefresh(Math.random().toString(36).substring(2, 15));
  };

  const watch_question_type = watch("question_type", false);

  useEffect(() => {
    setRefresh(Math.random().toString(36).substring(2, 15));
  }, [watch_question_type, handleSubmit]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
      <div>
        <div>
          <div className="flex flex-col">
            <div className="mt-2 mb-2">
              <input
                id="question_name"
                name="question_name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                type="text"
                {...register("question_name", { required: true })}
                placeholder="Enter question text"
              ></input>
            </div>
            <div className="mb-2 mt-2">
              <select
                id="question_type"
                name="question_type"
                type="select"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                {...register("question_type", { required: true })}
                placeholder="Enter Question Name"
              >
                <option value="">Please select question type</option>
                {question_types.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div>
          {watch_question_type == "info" ? (
            <div>
              <div className="mt-2 mb-2">
                <textarea
                  id="info"
                  name="info"
                  rows="4"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  type="textarea"
                  {...register("info", { required: true })}
                  placeholder="Please enter information text"
                ></textarea>
              </div>
            </div>
          ) : null}

          {watch_question_type == "short_answer" ? (
            <div>
              <div>
                <div className="mt-2 mb-2">
                  <input
                    disabled={true}
                    id="answer"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    name="answer"
                    type="text"
                    {...register("answer", { required: true })}
                    placeholder="Please enter short answer text"
                  ></input>
                </div>
              </div>
              <div>
                {data.type == "exam" ? (
                  <div className="mt-2 mb-2">
                    <input
                      id="correct_answer"
                      name="correct_answer"
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      {...register("correct_answer", { required: true })}
                      placeholder="Please enter correct short answer text"
                    ></input>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          {watch_question_type == "paragraph" ? (
            <div>
              <div>
                <div className="mb-2 mt-2">
                  <textarea
                    disabled={true}
                    id="answer"
                    rows="4"
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    name="answer"
                    type="textarea"
                    {...register("answer", { required: true })}
                    placeholder="Please enter long answer text"
                  ></textarea>
                </div>
              </div>
              <div>
                {data.type == "exam" ? (
                  <div className="mb-2 mt-2">
                    <textarea
                      id="correct_answer"
                      name="correct_answer"
                      rows="4"
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      type="textarea"
                      {...register("correct_answer", { required: true })}
                      placeholder="Please enter correct long answer text"
                    ></textarea>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          {watch_question_type == "single_choice" ? (
            <div>
              {options.map((option, option_index) => (
                <div key={option_index}>
                  <div className="flex flex-row mt-2 mb-2">
                    {data.type == "exam" ? (
                      <input
                        name="correct_answer"
                        type="radio"
                        className="mt-[16px] mr-[10px] w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                        {...register("correct_answer", { required: true })}
                        value={option.name.split(".")[1]}
                      ></input>
                    ) : (
                      <input disabled={true} type="radio" />
                    )}
                    <input
                      id={option.name}
                      name={option.name}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      type="text"
                      {...register(option.name, { required: true })}
                      placeholder={"Option " + (option_index + 1)}
                    ></input>
                    <button
                      className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2 ml-2"
                      href="#"
                      onClick={() => removeOption(option_index)}
                    >
                      <RiDeleteBinLine />
                    </button>
                  </div>
                </div>
              ))}
              <button
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2"
                href="#"
                onClick={() => addOption()}
              >
                Add option
              </button>
            </div>
          ) : null}

          {watch_question_type == "multiple_choice" ? (
            <div>
              {options.map((option, option_index) => (
                <div key={option_index}>
                  <div className="flex flex-row mt-2 mb-2">
                    {data.type == "exam" ? (
                      <input
                        name="correct_answer"
                        type="checkbox"
                        className="mt-[16px] mr-[10px] w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        {...register("correct_answer", { required: true })}
                        value={option.name.split(".")[1]}
                      ></input>
                    ) : (
                      <input disabled={true} type="checkbox" />
                    )}
                    <input
                      id={option.name}
                      name={option.name}
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      {...register(option.name, { required: true })}
                      placeholder={"Option " + (option_index + 1)}
                    ></input>
                    <button
                      className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2 ml-2"
                      href="#"
                      onClick={() => removeOption(option_index)}
                    >
                      <RiDeleteBinLine />
                    </button>
                  </div>
                </div>
              ))}
              <button
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2"
                href="#"
                onClick={() => addOption()}
              >
                Add option
              </button>
            </div>
          ) : null}

          {watch_question_type == "file_upload" ? (
            <div>
              <div>
                <textarea
                  type="textarea"
                  rows="4"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  id="answer"
                  name="answer"
                  {...register("answer", { required: true })}
                ></textarea>
              </div>
            </div>
          ) : null}
        </div>

        <button
          type="submit"
          className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 p-2 rounded mt-2 mb-2 w-full"
        >
          Update
        </button>
      </div>
    </form>
  );
}

export default EditForm;
