/* ACTION TYPE CONSTANTS

You can use any convention you wish here, but the name should remain UPPER_SNAKE_CASE for consistency.

*/

const SET_USER_LOGIN_TOKEN              = 'REDUX/TOKEN/SET_USER_LOGIN_TOKEN';
const SET_MERCHANT_BEARER_TOKEN         = 'REDUX/TOKEN/SET_MERCHANT_BEARER_TOKEN';
const SET_MERCHANT_BEARER_TOKEN_TEST    = 'REDUX/TOKEN/SET_MERCHANT_BEARER_TOKEN_TEST';
const SET_INTERPAYMENTS_API_PROD_TOKEN         = 'REDUX/TOKEN/SET_INTERPAYMENTS_API_PROD_TOKEN';
const SET_INTERPAYMENTS_API_TEST_TOKEN         = 'REDUX/TOKEN/SET_INTERPAYMENTS_API_TEST_TOKEN';
const GET_MERCHANT_BEARER_TOKEN         = 'REDUX/TOKEN/GET_MERCHANT_BEARER_TOKEN';
const GET_MERCHANT_BEARER_TOKEN_TEST    = 'REDUX/TOKEN/GET_MERCHANT_BEARER_TOKEN_TEST';
const GET_INTERPAYMENTS_API_TEST_TOKEN         = 'REDUX/TOKEN/GET_INTERPAYMENTS_API_TEST_TOKEN';
const GET_INTERPAYMENTS_API_PROD_TOKEN         = 'REDUX/TOKEN/GET_INTERPAYMENTS_API_PROD_TOKEN';
const PUSH_INTERPAYMENTS_API_TEST_TOKEN        = 'REDUX/TOKEN/PUSH_INTERPAYMENTS_API_TEST_TOKEN';
const PUSH_INTERPAYMENTS_API_PROD_TOKEN        = 'REDUX/TOKEN/PUSH_INTERPAYMENTS_API_PROD_TOKEN';

export {
    SET_USER_LOGIN_TOKEN,
    SET_MERCHANT_BEARER_TOKEN,
    SET_MERCHANT_BEARER_TOKEN_TEST,
    SET_INTERPAYMENTS_API_PROD_TOKEN,
    SET_INTERPAYMENTS_API_TEST_TOKEN,
    GET_MERCHANT_BEARER_TOKEN,
    GET_MERCHANT_BEARER_TOKEN_TEST,
    GET_INTERPAYMENTS_API_TEST_TOKEN,
    GET_INTERPAYMENTS_API_PROD_TOKEN,
    PUSH_INTERPAYMENTS_API_TEST_TOKEN,
    PUSH_INTERPAYMENTS_API_PROD_TOKEN,
};