import React from "react";
import { Modal } from "flowbite-react";

function GenericModal({ showModal, heading, body, toggle, size, position }) {
  return (
    <React.Fragment>
      <Modal show={showModal} onClose={toggle} size={size} position={position}>
        <Modal.Header>{heading}</Modal.Header>
        <Modal.Body>{body}</Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default GenericModal;
