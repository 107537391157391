import React from "react";

function Cloud() {
  return (
    <>
      <div data-aos="flip-up" className="max-w-xl mx-auto text-center mt-24">
        <h1 className="font-bold text-darken my-3 text-2xl">
          All-In-One <span className="text-yellow-500">Cloud Software.</span>
        </h1>
        <p className="leading-relaxed text-gray-500">
          LearnKidz is one powerful online software suite that combines all the
          tools needed to run a successful school or office.
        </p>
      </div>
      <div
        data-aos="fade-up"
        className="flex flex-col md:flex-row justify-center space-y-5 md:space-y-0 md:space-x-6 lg:space-x-10 mt-7"
      >
        {/* <div data-aos="fade-up" className="bg-white shadow-xl p-6 text-center rounded-xl"> */}
        <br />
        <a
          href="#"
          className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
        >
          <h1 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Online Billing, Invoicing, & Contracts
          </h1>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            Simple and secure control of your organization’s financial and legal
            transactions. Send customized invoices and contracts.
          </p>
        </a>

        <a
          href="#"
          className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
        >
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Easy Scheduling & Attendance Tracking
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            Schedule and reserve classrooms at one campus or multiple campuses.
            Keep detailed records of student attendance.
          </p>
        </a>
        <a
          href="#"
          className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
        >
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Customer Tracking
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            Automate and track emails to individuals or groups. LearnKidz’s
            built-in system helps organize your organization
          </p>
        </a>

        {/* <a href="#" className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Noteworthy technology acquisitions 2021</h5>
    <p className="font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
</a> */}
      </div>
      {/* </div> */}
    </>
  );
}

export default Cloud;
